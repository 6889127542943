/* Centering the loader */
.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Make it full screen, adjust as needed */
}

/* Circular Loader */
.custom-loader {
    border: 4px solid rgba(0, 0, 0, 0.1); /* Light border */
    border-top: 4px solid #3498db; /* Blue or any other color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

/* Loader Text */
.loader-message {
    margin-top: 16px;
    font-size: 1.2rem;
    color: #333;
}

/* Keyframes for spinning effect */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
