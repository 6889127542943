.dashboard {
  width: 100%;
}

.image-card {
  width: 70px;
  border-radius: 20%;
  border: 1px solid black;
  height: 100%;
  object-fit: cover;
}

.published-state {
  color: green;
}

.unpublished-state {
  width: 100px;
  background: grey;
  border-radius: 10%;
}
