.progress-bar-fill {
    height: 100%;
    transition: width 0.5s ease-in-out;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.close-button {
    user-select: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 2;
}

.progress-bar-container {
    display: flex;
    align-items: center;
    padding: 0px;
    border-radius: 8px;
    position: relative;
    height: 32px;
    overflow: hidden;
}

.file-name {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    font-size: 12px;
}
