.custom-upload .ant-upload-drag-container {
    display: flex !important;
    align-items: center;
    border-radius: 16px;
}

.custom-upload .ant-upload {
    padding: 0 !important;
    border-radius: 16px;
}

.custom-upload .ant-upload-drag {
    border: 1px dashed rgba(128, 128, 128, 1) !important;
}


.custom-upload .ant-upload-drag:hover {
    border: none !important;
}

.custom-upload .ant-upload-drag-hover {
    border: none !important;
    background-color: rgba(48, 144, 252, 0.6);
}
.custom-upload .ant-upload-btn {
    display: flex !important;
    color: rgba(128, 128, 128, 1);
    border-radius: 16px;
}

.custom-upload .ant-upload-btn:hover {
    display: flex !important;
    color: rgba(252, 252, 252, 1);
    background-color: rgba(48, 144, 252, 0.6);
    border-radius: 16px;
}