.my-characters-box {
    width: 168px;
    height: 168px;
    margin: 0px 4px 8px 4px;
}

.my-characters-box .my-characters-img {
    width: 168px;
    height: 168px;
    border-radius: 8px;
    cursor: pointer;
    object-fit: cover;
}

.my-characters-box .my-character-hover-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    /* bottom: 178px; */
}

.my-characters-box .my-character-hover-box .my-character-hover-buttons-container {
    width: 100%;
    padding: 10px;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.my-characters-box .my-character-hover-box .my-character-hover-delete-box {
}

.my-character-delete-icon, .my-character-edit-icon {
    width: 15px;
    height: 15px;
}
