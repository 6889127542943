@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mouse+Memoirs&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500&family=Dawning+of+a+New+Day&family=Inter:wght@600&family=Nanum+Pen+Script&display=swap');

body {
    --contexify-menu-minWidth: 100px;

    margin: 0;
    font-family: 'Inter', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: none;
    /*!* IE and Edge *!*/
    scrollbar-width: none;
    overscroll-behavior-x: none;
    /* Firefox */
}

:root {
    --color-selected-menu: #f0f0f0;
    ---color-start-gradient: rgba(24, 24, 24, 0);
    --text-color: black;
    --background-color: white;
    --current-color: #99e10a;
    --current-brush-type: hard;
    --current-brush-distance: 0;
    --current-brush-hardness: 100;
    --current-brush-pattern-id: blue-brush-stroke;
    --current-pressure-sensitivity-enabled: false;
    --current-stroke-width: 40;
    --current-paint-stroke-width: 8;
    --current-brush-stroke-width: 40;
    --current-mask-stroke-width: 200;
    --current-magic-eraser-stroke-width: 40;
    --current-eraser-width: 40;
    --current-opacity: 1;
    --current-bubble-stroke-size: 2;
    --current-bubble-stroke-color: #000000;
    --current-bubble-fill-color: #ffffff;
    --current-bubble-type: SpeechBubble;
    --current-tail-type: Straight;
    --current-bubble-radius: 0;
    --current-tail-width: 100;
    --current-has-tail: true;
    --tl-dpr-multiple: 100;
    --tl-dpr-multiple-px: calc(var(--tl-dpr-multiple) * 1px);
    --current-mode: 'mask';
    --current-font-style: draw;
    --current-font-size: 50;
    --current-font-color: #000000;
    --current-font-stroke-width: 5;
    --current-font-stroke-color: #ffffff;
    --font-draw: 'CCWildWordsLower Regular' !important;
    --font-comic: 'CCWildWords Regular' !important;
    --font-sans: 'Bangers', system-ui !important;
    --font-serif: Arial, Helvetica, sans-serif !important;
    --font-mono: 'Luckiest Guy', cursive !important;
    --font-acme: 'Acme', sans-serif !important;
    --font-memoir: 'Mouse Memoirs', sans-serif !important;
    --font-nanum: 'Nanum Pen Script', cursive !important;
    --font-caveat: 'Caveat', cursive !important;
    --font-dawning: 'Dawning of a New Day', cursive !important;
    --font-inter: 'Inter', sans-serif !important;
}

body * {
    font-family: 'Inter', sans-serif;
}

html {
    overscroll-behavior-x: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
    display: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ant-notification {
    z-index: 999999999999999999 !important;
}

.ant-message:has(.MessageLoading) {
    z-index: 999999999 !important;
}

.ant-message {
    top: 64px !important;
}


/* done for safari since tldraw has custom svg cursor that dont render correctly in safari */
.tl-container {
    --tl-cursor-none: none;
    --tl-cursor-default: default !important;
    --tl-cursor-pointer: pointer !important;
    --tl-cursor-cross: crosshair !important;
    --tl-cursor-move: move !important;
    --tl-cursor-grab: grab !important;
    --tl-cursor-grabbing: grabbing !important;
    --tl-cursor-text: text !important;
    --tl-cursor-zoom-in: zoom-in !important;
    --tl-cursor-zoom-out: zoom-out !important;
}

.tl-hitarea-stroke {
    stroke-width: var(--current-stroke-width) !important;
}

.tl-hitarea-fill {
    stroke-width: var(--current-stroke-width) !important;
}

.tl-hitarea-fill-solid {
    stroke-width: var(--current-stroke-width) !important;
}

.image {
    flex: 0 0 calc(25% - 10px);
    margin: 5px;
    text-align: center;
    position: relative;
}

/* Update for single image */
.image:nth-last-child(1):nth-child(1) {
    flex: 0 0 calc(50% - 10px);
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.image p {
    margin-top: 8px;
}
